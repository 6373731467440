import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaInfoCircle, FaCog, FaProjectDiagram, FaEnvelope } from 'react-icons/fa';

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light" style={{ backgroundColor: 'rgb(54, 69, 79)', height: '14vh' }}>
      <div className="container pt-2">
        <Link className="navbar-brand me-4" to="/" style={{ color: 'rgb(255, 255, 255)', textDecoration: 'none' }}>
          <h2 style={{ fontFamily:'junge',fontSize: '200%', margin: 0 }}>Byron Korir</h2>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav" style={{ backgroundColor: 'rgb(54, 69, 79,0)'}}>
          <ul className="navbar-nav ms-auto p-2 rounded" style={{ backgroundColor: 'rgb(54, 69, 79)', width:'fit-content'}}>
            <li className="nav-item">
              <Link className="nav-link" to="/" style={{ color: 'rgb(152, 255, 152)' }}>
                <FaHome style={{ marginRight: '5px' }} /> Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about" style={{ color: 'rgb(152, 255, 152)' }}>
                <FaInfoCircle style={{ marginRight: '5px' }} /> About
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/services" style={{ color: 'rgb(152, 255, 152)' }}>
                <FaCog style={{ marginRight: '5px' }} /> Services
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="/projects" style={{ color: 'rgb(152, 255, 152)' }}>
                <FaProjectDiagram style={{ marginRight: '5px' }} /> Projects
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact" style={{ color: 'rgb(152, 255, 152)' }}>
                <FaEnvelope style={{ marginRight: '5px' }} /> Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
