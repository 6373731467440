import React from 'react';
import profileImage from '../images/me.png'; // Import your image file
import { FaGithub, FaLinkedin, FaTwitter, FaEnvelope } from 'react-icons/fa';

export default function Landing() {
  return (
    <div style={{ height: 'fit-content', overflowY: 'auto' }}> {/* Primary Background: Cool Gray */}
      <div className="container-fluid d-flex flex-column align-items-center" style={{ padding: '100px 50px' }}>
        <img
          className="mr-2 rounded-circle d-flex align-items-center justify-content-center"
          style={{ width: '16rem', height: '16rem' }} // Border: Soft Coral
          src={profileImage}
          alt="Byron Korir"
        />
        <div className="text-center mt-4">
          <h1 className="m-2" style={{ fontSize: '200%', fontFamily: "cursive", fontWeight: 'bolder', color: 'rgb(54, 69, 79)' }}> {/* Text: Mint Green */}
            Byron Korir
          </h1>
          <p style={{ fontSize: '120%', color: 'rgb(54, 54, 54)' }}> {/* Text: Dark Gray */}
            A creative & passionate Full-stack developer
          </p>
          <div className="mt-3 text-info">
            <a href="https://github.com/ByronKorir" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', margin: '0 10px', fontSize: '150%' }}><FaGithub /></a>
            <a href="http://www.linkedin.com/in/byron-korir-3141992a2" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', margin: '0 10px', fontSize: '150%' }}><FaLinkedin /></a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', margin: '0 10px', fontSize: '150%' }}><FaTwitter /></a>
            <a href="mailto:byronkorir2@gmail.com" style={{ color: 'blue', margin: '0 10px', fontSize: '150%' }}><FaEnvelope /></a>
          </div>
        </div>
      </div>
    </div>
  );
}
