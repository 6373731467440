import React, {useRef} from 'react';
import Landing from '../components/Landing';
import About from './About';
import SkillsIcons from './Skills';
import Services from '../components/Services';
import Contact from './Contact';



export default function Home() {
  const sectionRef = useRef(null);

  // Step 3: Create a scroll function
  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div >
      <Landing/>
      <About scrollToSection={scrollToSection}/>
      <SkillsIcons/>
      <Services/>
      <Contact sectionRef={sectionRef}/>
      
    </div>
  );
}

