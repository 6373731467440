import React from 'react';
import airbnb from '../images/airbnb.png';
import pyywars from '../images/pyywars.png';
import besh from '../images/besh.png';

const projectsList = [
  {
    icon: besh,
    title: "Besh African Adventures Tour App",
    description: "A sophisticated full-stack application for managing and booking tours. It provides a seamless user experience for the staff, clients, and agents. It has improved service delivery and customer satisfaction by leaps and bounds.",
    technologies: ["JavaScript", "React.js", "Python", "Flask", "PostgreSQL"],
    features: ["Clients inquiry management",
     "Itinerary and package generation and management",
      "Tourist destinations and accommodation management", 
      "Agents colaboration (packages and itineraries sharing)",
       "Improved communication between agents, clients and staff(Automated emails)", 
       "Tourist transport management"],
    link: null,
  },
  {
    icon: pyywars,
    title: "PYYWARS (simple Codewars Python clone)",
    description: "A dynamic platform inspired by Codewars, focused on Python proficiency. Users engage in coding challenges and contribute their own.",
    technologies: ["JavaScript", "React.js", "Python", "Flask", "PostgreSQL"],
    features: ["Curated coding challenges", "Interactive voting system", "User-contributed challenges"],
    link: "https://65eca2b928bd13cddd02b563--extraordinary-sprite-3223b4.netlify.app/",
  },
  {
    icon: airbnb,
    title: "NightHouse (AIRBNB)",
    description: "A full-stack application inspired by Airbnb, offering user registration, profile management, property viewing, booking, and transaction history.",
    technologies: ["JavaScript", "React.js", "Python", "Flask", "PostgreSQL"],
    features: ["User registration and login", "Profile management", "Property viewing and booking", "Transaction history"],
    link: "https://65b40a0c51be0c031dcbb855--incandescent-heliotrope-d56da9.netlify.app/",
  },
];

export default function Projects() {
  return (
    <div className="container py-5">
      <h1 style={{ fontFamily: 'Inknut Antiqua', fontSize: '3rem', fontWeight: 'bold' }} className="text-dark text-center mb-5 pt-2">My Projects</h1>
      <div className="row">
        {projectsList.map((project, index) => (
          <div key={index} className="col-lg-6 mb-4 d-flex align-items-stretch">
            <div className="border h-100 border-success mx-auto p-3" style={{ boxShadow: '0 4px 8px rgba(0,0,0,0.1)', borderRadius: '10px',backgroundColor:'beige' }}>
              <div className="card-body d-flex flex-column">
                <h4 className="card-title text-success text-center" style={{ fontFamily: 'Inknut Antiqua', fontSize: '1.5rem' }}>{project.title}</h4>
                <div className="mb-3 text-center">
                  <img src={project.icon} style={{ height: '15vh', maxHeight: '100px', objectFit: 'contain' }} alt='Project Icon' className='img-fluid' />
                </div>
                <div className="text-start mb-3 flex-grow-1">
                  <p className="card-text text-dark" style={{ fontFamily: 'Arial, sans-serif', fontSize: '0.9rem', lineHeight: '1.6' }}>{project.description}</p>
                  <div style={{ marginTop: '10px' }}>
                    <strong  className='text-success' >Technologies:</strong>
                    <ul className='text-dark' style={{ paddingLeft: '20px' }}>
                      {project.technologies.map((tech, i) => (
                        <li key={i} style={{ listStyleType: 'disc', fontSize: '0.9rem' }}>{tech}</li>
                      ))}
                    </ul>
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <strong  className='text-success' >Features:</strong>
                    <ul  className='text-dark'  style={{ paddingLeft: '20px' }}>
                      {project.features.map((feature, i) => (
                        <li key={i} style={{ listStyleType: 'disc', fontSize: '0.9rem' }}>{feature}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                {project?.link && (

                <a href={project.link} className="btn btn-success mt-3" style={{ alignSelf: 'center', width: '50%' }} target="_blank" rel="noopener noreferrer">View Project</a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
