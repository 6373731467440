import React from 'react';
import { FaCode, FaLaptopCode, FaServer, FaDatabase, FaPaintBrush, FaLock, FaCodeBranch, FaMobileAlt } from 'react-icons/fa';

const servicesList = [
  { title: "Full-Stack Web Development", description: "Develop dynamic and interactive web applications from front-end to back-end using JavaScript, React.js, Python, Flask, HTML, CSS, and Bootstrap.", icon: <FaCode size={80} /> },
  { title: "Custom Web Application Development", description: "Build bespoke web applications tailored to clients' specific requirements, leveraging expertise in both front-end and back-end technologies.", icon: <FaLaptopCode size={80} /> },
  { title: "API Development", description: "Design, develop, and implement RESTful APIs using Python and Flask to enable seamless communication between different components of web applications.", icon: <FaServer size={80} /> },
  { title: "Front-End Development", description: "Create responsive and user-friendly interfaces using React.js, HTML, CSS, and Bootstrap, focusing on delivering an exceptional user experience.", icon: <FaCode size={80} /> },
  { title: "Back-End Development", description: "Develop robust server-side logic and database management systems using Python and Flask, ensuring scalability, security, and performance.", icon: <FaDatabase size={80} /> },
  { title: "Database Design and Implementation", description: "Design and optimize databases using SQL and SQLite databases, integrating them seamlessly into web applications.", icon: <FaDatabase size={80} /> },
  { title: "Single Page Applications (SPAs)", description: "Develop SPAs using React.js to deliver a smooth user experience without page reloads, enhancing performance and usability.", icon: <FaCode size={80} /> },
  { title: "Responsive Web Design", description: "Ensure websites and web applications adapt across various devices for a consistent user experience on desktops, tablets, and mobiles.", icon: <FaMobileAlt size={80} /> },
  { title: "UI/UX Design Integration", description: "Collaborate with designers to translate designs into functional web interfaces using CSS, Bootstrap, and JavaScript libraries.", icon: <FaPaintBrush size={80} /> },
  { title: "Authentication and Authorization", description: "Implement secure authentication and authorization mechanisms to protect against unauthorized access.", icon: <FaLock size={80} /> },
  { title: "Version Control and Deployment", description: "Utilize Git for code management and deploy applications using platforms like Render or Netlify.", icon: <FaCodeBranch size={80} /> }
];

export default function Services() {
  return (
    <div style={{backgroundColor:'rgb(54, 69, 79,0.5)'}}>
    <div className="container py-5" >
      <h1 style={{ fontFamily: 'inknut antiqua',fontSize:'300%'  }} className='text-white text-center mb-5 pt-2'>Services </h1>
      <div className="row">
        {servicesList.map((service, index) => (
          <div key={index} className="col-lg-4 mb-4">
            <div className="card h-100 border-primary">
              <div className="card-body text-center">
                <div className="mb-3 text-success">
                  {service.icon}
                </div>
                <h4 className="card-title text-primary">{service.title}</h4>
                <p className="card-text text-dark">{service.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
}
