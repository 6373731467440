import React from 'react';
import { FaEnvelope, FaPhone, FaGithub, FaLinkedin } from 'react-icons/fa';
import profilePicture from '../images/me.png';

const Contact = ({sectionRef}) => {
  return (
    <div
    ref={sectionRef}
    style={{minWidth:'50%', maxWidth:'fit-content', height:'85vh'}}
     className='container py-4 mx-auto' >
      <div style={contactContainer}>
      <h2 className='mb-2' style={{
        margin: '0',
        color: '#333',
        fontFamily:'junge',
        fontSize:'300%'
      }}>Contacts Info</h2>
        <div 
        className='container rounded border border-success shadow-lg p-4 d-flex justify-content-between flex-wrap' 
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '30px',
          backgroundColor:'rgb(54, 69, 79,0.2)'
}}>
          <img src={profilePicture} alt="Byron" style={profilePictureStyle} />
          <div>
           
            <p style={{
              color: '#000',
              marginBottom: '10px',
            }}>Feel free to contact me!</p>
            <div className='card shadow  p-4' style={{
  marginTop: '10px',
  backgroundColor:'rgb(54, 69, 79,0.7)'
}}>
              <a href="mailto:byronkorir2@gmail.com" style={linkStyle}><FaEnvelope color='rgb(152, 255, 152)'/> byronkorir2@gmail.com</a>
              <a href="tel:+254725 499649" style={linkStyle}><FaPhone color='rgb(152, 255, 152)' /> +254725 499649</a>
              <a href="https://github.com/ByronKorir" target="_blank" rel="noopener noreferrer" style={linkStyle}><FaGithub  color='rgb(152, 255, 152)'/> GitHub</a>
              <a href="http://www.linkedin.com/in/byron-korir-3141992a2" target="_blank" rel="noopener noreferrer" style={linkStyle}><FaLinkedin  color='rgb(152, 255, 152)'/> LinkedIn</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;



const contactContainer = {
  maxWidth: '800px',
  margin: '0 auto',
  padding: '0 15px',
};



const profilePictureStyle = {
  width: '150px',
  height: '150px',
  borderRadius: '50%',
  marginRight: '20px',
};

// const nameStyle = ;

// const contactText = ;

// const socialLinks = ;

const linkStyle = {
  color: '#fff',
  textDecoration: 'none',
  display: 'block',
  marginBottom: '5px',
};
