import React from 'react';
// Importing specific icons from react-icons
import { FaReact, FaPython, FaHtml5, FaCss3Alt, FaJsSquare, FaDatabase, FaGithub } from 'react-icons/fa';
import { SiFlask, SiSqlite, SiNetlify, SiGithubactions } from 'react-icons/si';

// Define the icons with names and inline style for font size
const skillsIcons = [
  { icon: <FaHtml5 style={{ fontSize: '1500%', color: '#E34F26' }} />, name: 'HTML5' },
  { icon: <FaCss3Alt style={{ fontSize: '1500%', color: '#1572B6' }} />, name: 'CSS3' },
  { icon: <FaJsSquare style={{ fontSize: '1500%', color: '#F7DF1E' }} />, name: 'JavaScript' },
  { icon: <FaReact style={{ fontSize: '1500%', color: '#61DAFB' }} />, name: 'React' },
  { icon: <FaPython style={{ fontSize: '1500%', color: '#3776AB' }} />, name: 'Python' },
  { icon: <SiFlask style={{ fontSize: '1500%', color: '#000000' }} />, name: 'Flask' },
  { icon: <FaDatabase style={{ fontSize: '1500%', color: '#F80000' }} />, name: 'SQL' },
  { icon: <SiSqlite style={{ fontSize: '1500%', color: '#003B57' }} />, name: 'SQLite' },
  { icon: <SiGithubactions style={{ fontSize: '1500%', color: '#2088FF' }} />, name: 'RESTful APIs' },
  { icon: <FaGithub style={{ fontSize: '1500%', color: '#181717' }} />, name: 'GitHub' },
  { icon: <SiNetlify style={{ fontSize: '1500%', color: '#00C7B7' }} />, name: 'Netlify' },
  // { icon: <FaPostgres style={{ fontSize: '1500%', color: '#336791' }} />, name: 'PostgreSQL' }, // Adding PostgreSQL
];

export default function SkillsIcons() {
  return (
    <div style={{ minHeight: '55vh' }}>
      <div>
        <h1 style={{ fontFamily: 'inknut antiqua',fontSize:'200%',color:'black'}} className='ms-5 mb-5 pt-2'>My Skills </h1>
      </div>

      {/* Container to hide scrollbar */}
      <div style={{
          overflowX: 'auto',
          overflowY: 'hidden',
          paddingBottom: '20px', // Add padding bottom to push the scrollbar out of view
          msOverflowStyle: 'none', /* IE and Edge */
          scrollbarWidth: 'none', /* Firefox */
      }}>
        <div className="" style={{ display: 'flex', width: 'fit-content' }}>
          {skillsIcons.map((item, index) => (
            <div className="skill-card mx-4 mb-5 text-center" key={index} >
              <div className='shadow-lg' style={{ width: 'fit-content', height: '25vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'beige', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>{item.icon}</div>
              <h4 className='mt-1' style={{ color:"royalblue", fontSize: '150%', fontWeight: 'bold' }}>{item.name}</h4>
            </div>
          ))}
        </div>
      </div>

      {/* Hiding scrollbar for Chrome, Safari, Opera */}
      {/* <style>{`
        ::-webkit-scrollbar {
          display: none;
        }
      `}</style> */}
      {/* <div>
          <h5 className='text-success ms-5 mt-2 mb-3'>---------scroll right</h5>
        </div> */}
    </div>
  );
}
 