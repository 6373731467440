import React from 'react'
import { Outlet } from 'react-router-dom'

import Header from './Header'

export default function Layout() {
  return (
   <div >
    <div className='bg-secondary bg-opacity-50'>
    <Header/>
    </div>
    <div style={{backgroundColor: 'rgb(152, 255, 155)',color:'whitesmoke'}}>
      <Outlet/>
    </div>
      

   </div>
  )
}
